<template lang="pug">
  .filters
    .row.align-items-center
      .col-lg-4.col-md-6.col-sm-12
        p.filter-title {{ referenceTitle }}
        AppDropdown(
          :class="referenceClass"
          checkbox
          batch-select
          allow-empty
          close-on-select
          searchable
          multiple
          :loading="referencesLoading"
          value-key="id"
          title-key="name"
          :value="filters.references"
          :items="references"
          @select="$emit('change:references', $event)"
          @closed="$emit('closed:references')"
          order-direction="keep"
        )
      .col-lg-4.col-md-6.col-sm-12
        p.filter-title {{ $t("sold_out.attributes.car_class") }}
        AppDropdown.car-class-name(
          v-if="isEmpty(filters.references)"
          :disabled="true"
          v-b-tooltip.hover
          :title="selectReferenceText"
        )
        AppDropdown.car-class-name(
          v-else
          checkbox
          batch-select
          multiple
          allow-empty
          close-on-select
          searchable
          :loading="carClassesLoading"
          value-key="id"
          title-key="name"
          :placeholder="$t('sold_out.select_car_classes')"
          :value="filters.carClasses"
          :items="carClasses"
          order-direction="preorder"
          :is-item-disabled="isItemDisabled"
          @select="$emit('change:car-classes', $event)"
          @closed="$emit('closed:car-classes')"
        )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { isEmpty } from "lodash-es"
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    mixins: [withPermissions],

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },
      references: {
        type: Array,
        default: () => new Array()
      },
      referencesLoading: {
        type: Boolean,
        default: false
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      referenceClass() {
        return isSharedInventoryEnabled() ? "shared-inventory-group" : "shop-name"
      },

      referenceTitle() {
        return isSharedInventoryEnabled()
          ? this.$t("sold_out.attributes.inventory_group")
          : this.$t("sold_out.attributes.shop")
      },

      selectReferenceText() {
        return isSharedInventoryEnabled()
          ? this.$t("sold_out.select_inventory_group_first")
          : this.$t("sold_out.select_shop_first")
      }
    },

    methods: {
      isEmpty,

      isItemDisabled({ selectable }) {
        return isSharedInventoryEnabled() && !selectable
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/variables.sass"

  .filters
    +filter-title

    margin-bottom: 20px
</style>
